import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui/styles";
import { lighten } from "@mui/system";

import { Typography, LinearProgress } from "@mui/material";

// Utils
import { getColor } from "../../../Utils/volumeControl";
import { formatNumber } from "../../../Utils/commons";
import { FragmentType, getFragmentData } from "../../../gql";
import { volumeControlFragment } from "../pages/mainPage";
export interface StyleProps {
  color: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: (props) => ({ height: 10, backgroundColor: lighten(props.color, 0.5) }),
  bar: (props) => ({ borderRadius: 20, backgroundColor: props.color }),
  totalValue: (props) => ({
    display: "inline-block",
    color: lighten(props.color, 0.5),
  }),
  currentValue: (props) => ({ display: "inline-block", color: props.color }),
  volumeContext: {
    flex: 1,
    paddingTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  remainingValue: {
    margin: theme.spacing(1),
  },
}));

export const VolumeBar = (props: {
  volume: FragmentType<typeof volumeControlFragment>;
}) => {
  const { t } = useTranslation();
  const volume = getFragmentData(volumeControlFragment, props.volume);
  const { quota, remainingQuota, consumedQuota } = volume;
  const consumedPercent = consumedQuota / quota;
  const color = getColor(consumedPercent);
  const classes = useStyles({ color: color });

  return (
    <div className={classes.volumeContext}>
      <Typography variant="h2" align="left" className={classes.currentValue}>
        {`${formatNumber(consumedQuota)}`}
      </Typography>
      <Typography variant="h6" align="left" className={classes.currentValue}>
        {`${t("consumed")} `}
      </Typography>
      <Typography variant="h6" align="left" className={classes.totalValue}>
        {` /${formatNumber(quota)} ${t("quota")}`}
      </Typography>

      <LinearProgress
        className={classes.margin}
        variant="determinate"
        value={consumedPercent * 100}
        classes={{ root: classes.root, bar: classes.bar }}
      />
      <Typography
        variant="body1"
        align="right"
        className={classes.remainingValue}
      >
        {`${formatNumber(remainingQuota)} ${t("remaining quota")}`}
      </Typography>
    </div>
  );
};
