import { create } from "zustand";

import {
  DraftOrderFragment,
  DraftOrderPositionFragment,
  DraftVolumeFragment,
  InputMaybe,
  MarkableFragment,
  MarkingProductFragment,
  OrderOrderTransportationMethodChoices,
  OrderPositionInput,
  Scalars,
} from "../../gql/graphql";
import { isEqual } from "lodash";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";
import {
  DraftOrderQuery,
  draftVolumeFragment,
  DraftVolumeQuery,
} from "./components/draftOrderQueries";
import { client } from "../../Api/ApiClient";
import { getFragmentData } from "../../gql";
import { markingProductFragment } from "../Storefront/pages/ProductList";
import { OrderOrderTransportationMethodChoices as TransportationMethods } from "../../gql/graphql";

export type CartItem = {
  uuid?: string;
  quantity: number;
  markingProduct: MarkingProductFragment;
  markable: MarkableFragment;
  additionalInfo: string | undefined;
};

export const equalItem = (thisItem: CartItem, thatItem: CartItem): boolean => {
  if (thisItem.uuid && thatItem.uuid && thisItem.uuid === thatItem.uuid)
    return true;

  return (
    thisItem.markable.uuid === thatItem.markable.uuid &&
    thisItem.markingProduct.uuid === thatItem.markingProduct.uuid &&
    thisItem.additionalInfo === thatItem.additionalInfo
  );
};

export interface ShoppingCart {
  cartItems: CartItem[];
  deliveryAddress?: Scalars["UUID"];
  setDeliveryAddress: (addressId: Scalars["UUID"]) => void;
  transportationMethod?:
    | InputMaybe<OrderOrderTransportationMethodChoices>
    | undefined;
  setTransportationMethod: (
    transportationMethod:
      | InputMaybe<OrderOrderTransportationMethodChoices>
      | undefined
  ) => void;
  addCartItem: (cartItem: CartItem) => void;
  addCartItems: (cartItems: CartItem[]) => void;
  removeCartItem: (uuid: string | undefined) => void;
  updateQuantity: (uuid: string | undefined, quantity: number) => void;
  clearCart: () => void;
  draftOrder: DraftOrderFragment | undefined;
  draftVolumes: readonly DraftVolumeFragment[] | null | undefined;
}

export const isDuplicate = (
  cartItem: CartItem,
  cartItems: CartItem[]
): boolean => {
  return findDuplicate(cartItem, cartItems) !== undefined;
};

export const isEqualAdditionalInfo = (
  thisInfo: string,
  thatInfo: string
): boolean => {
  return isEqual(JSON.parse(thisInfo), JSON.parse(thatInfo));
};

export const findDuplicate = (
  cartItem: CartItem,
  cartItems: CartItem[]
): CartItem | undefined => {
  return cartItems.find(
    (item) =>
      isEqual(cartItem.markable.uuid, item.markable.uuid) &&
      isEqual(cartItem.markingProduct.uuid, item.markingProduct.uuid) &&
      (cartItem.additionalInfo && item.additionalInfo
        ? isEqualAdditionalInfo(cartItem.additionalInfo, item.additionalInfo)
        : true)
  );
};

export const isEmpty = (state: ShoppingCart): boolean => {
  return !(state.cartItems.length > 0);
};

type Validation = (cartItems: CartItem[]) => boolean;

export const hasMixedProducts: Validation = (cartItems: CartItem[]) => {
  if (!cartItems || cartItems.length === 0) return false;
  return (
    cartItems.some(
      (item) => item.markingProduct?.markingProductType === ProductType.Digital
    ) &&
    cartItems.some(
      (item) => item.markingProduct?.markingProductType === ProductType.Physical
    )
  );
};

// FIXME move this into the ShoppingCart!!!
export const findCartItemForOrderposition = (
  cartItems: CartItem[],
  orderPosition: DraftOrderPositionFragment
): CartItem | undefined => {
  const markingProduct = getFragmentData(
    markingProductFragment,
    orderPosition.markingProduct
  );
  const foundItem = cartItems.find(
    (item) =>
      item.markable.uuid === orderPosition.markable.uuid &&
      item.markingProduct.uuid === markingProduct.uuid &&
      (orderPosition.additionalInfo !== null && item.additionalInfo !== "{}"
        ? isEqual(
            JSON.parse(orderPosition.additionalInfo),
            JSON.parse(item.additionalInfo || "{}")
          )
        : true)
  );

  return foundItem;
};

type CategorizedOrderPositions = {
  groupName: string;
  orderPositions: DraftOrderPositionFragment[];
  volume: DraftVolumeFragment;
};

export const categorizeOrderPositions = (
  orderPositions: readonly DraftOrderPositionFragment[],
  draftVolumes: readonly DraftVolumeFragment[]
): CategorizedOrderPositions[] => {
  if (
    !(
      orderPositions &&
      orderPositions.length > 0 &&
      draftVolumes &&
      draftVolumes.length > 0
    )
  )
    return [];
  else {
    return orderPositions.reduce(
      (
        categories: CategorizedOrderPositions[],
        orderPosition: DraftOrderPositionFragment
      ) => {
        const group = orderPosition.markable;

        const existingCategory = categories.find(
          (category) => category.groupName === group.name
        );
        if (existingCategory) {
          existingCategory.orderPositions.push(orderPosition);
        } else {
          categories.push({
            groupName: group.name!,
            orderPositions: [orderPosition],
            volume: draftVolumes.find(
              (volume) => volume.markable.uuid === group.uuid
            )!,
          });
        }

        return categories;
      },
      []
    );
  }
};

export const isValidOrder = (
  orderPositions: readonly DraftOrderPositionFragment[],
  draftVolumes: readonly DraftVolumeFragment[]
): boolean => {
  return (
    draftVolumes &&
    draftVolumes.length > 0 &&
    orderPositions &&
    orderPositions.length > 0
  );
};

/**
 * You can checkout:
 * - when you have a valid order (min-values reached etc)
 * - you do not exceed your volumes
 */
export const canCheckout = ({
  cartItems,
  draftOrder,
  draftVolumes,
}: {
  cartItems: CartItem[];
  draftOrder: DraftOrderFragment | undefined;
  draftVolumes: readonly DraftVolumeFragment[] | null | undefined;
}): boolean => {
  return (
    draftOrder !== undefined &&
    draftVolumes !== null &&
    draftVolumes !== undefined &&
    draftVolumes &&
    !volumeExceeded(draftVolumes) &&
    !hasMixedProducts(cartItems)
  );
};

export const volumeExceeded = (
  draftVolumes: readonly DraftVolumeFragment[]
): boolean => {
  return (
    draftVolumes.length > 0 &&
    draftVolumes.some(
      (volume: DraftVolumeFragment) =>
        volume && volume.status === "QUOTA_EXCEEDED"
    )
  );
};

const hasPhysicalProducts = (cartItems: CartItem[]): boolean => {
  return cartItems.some(
    (cartitem) =>
      cartitem.markingProduct.markingProductType === ProductType.Physical
  );
};

type DraftVariables = {
  positions: OrderPositionInput[];
  deliveryAddress?: Scalars["UUID"];
  transportationMethod?:
    | InputMaybe<OrderOrderTransportationMethodChoices>
    | undefined;
};

export const useShoppingCart = create<ShoppingCart>((set) => {
  const fetchDraftOrder = (variables: DraftVariables) => {
    client
      .query({
        query: DraftOrderQuery,
        variables: variables,
        fetchPolicy: "cache-first",
      })
      .then((response) =>
        // FIXME here we need to re-connect the cartItems with the draftOrderPositions
        set((state) => ({
          draftOrder: response.data.draftOrder,
        }))
      );
  };

  const fetchDraftVolume = (variables: DraftVariables): void => {
    client
      .query({
        query: DraftVolumeQuery,
        variables: variables,
        fetchPolicy: "cache-first",
      })
      .then((response) =>
        set((state) => ({
          draftVolumes: getFragmentData(
            draftVolumeFragment,
            response.data.draftVolume
          ),
        }))
      );
  };

  const updateDraft = (variables: DraftVariables): void => {
    fetchDraftOrder(variables);
    fetchDraftVolume(variables);
  };

  const getInputVariables = (state: ShoppingCart) => {
    console.log("get draftorder-input from state", state);
    const orderVariables: any = {
      positions: state.cartItems.map((ci) => ({
        markingProduct: ci.markingProduct.uuid,
        quantity: ci.quantity,
        markable: ci.markable.uuid,
        additionalInfo: ci.additionalInfo,
      })),
      deliveryAddress:
        state.deliveryAddress && hasPhysicalProducts(state.cartItems)
          ? state.deliveryAddress
          : null,
      transportationMethod: state.deliveryAddress
        ? state.transportationMethod
        : null,
    };

    return orderVariables;
  };

  const addToExistingItems = (
    orderPosition: CartItem,
    cartItems: CartItem[]
  ): CartItem[] => {
    const existingItem = findDuplicate(orderPosition, cartItems);

    return (cartItems = [
      ...cartItems.filter((item) => item.uuid !== existingItem?.uuid),
      {
        ...orderPosition,
        uuid: crypto.randomUUID(),
        quantity: orderPosition.quantity + (existingItem?.quantity || 0),
      },
    ]);
  };
  return {
    cartItems: [],
    draftOrder: undefined,
    draftVolumes: undefined,
    addCartItem: (orderPosition: CartItem): void => {
      set((state: ShoppingCart) => {
        const cartItems = addToExistingItems(orderPosition, state.cartItems);
        updateDraft(getInputVariables({ ...state, cartItems }));
        return { cartItems };
      });
    },
    addCartItems: (orderPositions: CartItem[]): void => {
      set((state: ShoppingCart) => {
        const cartItems: CartItem[] = orderPositions.reduce(
          (newCartItems: CartItem[], orderPosition) =>
            addToExistingItems(orderPosition, newCartItems),
          state.cartItems
        );
        updateDraft(getInputVariables({ ...state, cartItems }));
        return { cartItems };
      });
    },
    removeCartItem: (uuid: string | undefined) => {
      set((state: ShoppingCart) => {
        const cartItems = state.cartItems.filter(
          (item) => !(item.uuid === uuid)
        );
        updateDraft(getInputVariables({ ...state, cartItems }));
        return { cartItems };
      });
    },
    updateQuantity: (uuid: string | undefined, quantity: number) => {
      set((state: ShoppingCart) => {
        const cartItems = state.cartItems.map((item) =>
          item.uuid === uuid
            ? {
                ...item,
                quantity: quantity,
              }
            : item
        );
        updateDraft(getInputVariables({ ...state, cartItems }));
        return { cartItems };
      });
    },
    setDeliveryAddress: (addressId: Scalars["UUID"]) => {
      set((state: ShoppingCart) => {
        updateDraft(
          getInputVariables({
            ...state,
            deliveryAddress: addressId,
            transportationMethod: addressId
              ? TransportationMethods.ScribosHandled
              : null,
          })
        );
        return { deliveryAddress: addressId };
      });
    },
    setTransportationMethod: (
      transportationMethod:
        | InputMaybe<OrderOrderTransportationMethodChoices>
        | undefined
    ) => {
      set((state: ShoppingCart) => {
        updateDraft(getInputVariables({ ...state, transportationMethod }));
        return { transportationMethod };
      });
    },

    clearCart: () => {
      set((state: ShoppingCart) => ({
        cartItems: [],
        draftOrder: undefined,
        draftVolumes: undefined,
      }));
    },
  };
});
