import React, { createContext, useState, useContext } from "react";

export type Option = {
  value: string;
  label: string;
};

type KeyValuePair = {
  key: string;
  value: string;
};

interface AdditionalInfoStore {
  values: KeyValuePair[];
  setValue: (pair: KeyValuePair) => void;
  resetValues: () => void;
}

export const AdditionalInfoStoreContext = createContext<AdditionalInfoStore>({
  values: [],
  setValue: (pair) => {},
  resetValues: () => {},
});

export const AdditionalInfoStoreContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  // state for the values of all filters
  const [values, setValues] = useState<KeyValuePair[]>([]);

  const setValue = (pair: KeyValuePair) => {
    setValues([...values.filter((value) => value.key !== pair.key), pair]);
  };

  const resetValues = () => setValues([]);

  const value = {
    values,
    setValue,
    resetValues,
  };

  return (
    <AdditionalInfoStoreContext.Provider value={value}>
      {children}
    </AdditionalInfoStoreContext.Provider>
  );
};

export const useAdditionalInfoStoreContext = (): AdditionalInfoStore => {
  return useContext(AdditionalInfoStoreContext);
};
