import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { FragmentType, getFragmentData } from "../../../gql";
import { volumeControlFragment } from "../pages/mainPage";

export const GroupHeader = (props: {
  volume: FragmentType<typeof volumeControlFragment>;
}) => {
  const { t } = useTranslation();
  const volume = getFragmentData(volumeControlFragment, props.volume);

  return (
    <>
      {volume ? (
        <>
          <Typography variant="h6" style={{ marginRight: "8px" }}>
            {volume.markable.name}
          </Typography>
        </>
      ) : (
        <>{t("No Product Group")}</>
      )}
    </>
  );
};
