import React from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

// Material UI

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Paper, Divider, Typography } from "@mui/material";
//Query
import { useQuery } from "@apollo/client";

// Components
import { InfoBar } from "../../../Components/InfoBar";
import { VolumeBar } from "../components/VolumeBar";
import { GroupHeader } from "../components/GroupHeader";
import { LicensingEvent } from "../components/LicensingEvent";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";

// Utils
import { getColor } from "../../../Utils/volumeControl";
import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outline: { outline: `1px solid ${theme.palette.grey[300]}` },
  })
);

interface params {
  id: string;
}

const volumeControlQuery = graphql(/* GraphQL */ `
  query volumeControl($markable: UUID!) {
    volumeControl(markable: $markable) {
      ...VolumeControlItem
      consumedQuota
      quota
    }
  }
`);

export const DetailPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { match } = useReactRouter();
  const id = (match.params as params).id;
  const classes = useStyles(theme);

  const { data, loading, error } = useQuery(volumeControlQuery, {
    variables: { markable: id },
    fetchPolicy: "network-only",
  });

  if (error) throw error;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data?.volumeControl) throw Error;

  const getMessage = (number: number) => {
    const percentage = Math.round(number * 100);

    if (number < 0.7) {
      return (
        <>
          {t(
            "You ordered {{percentage}} % of the available marking volume of this category.",
            { percentage: isNaN(percentage) ? 0 : percentage }
          )}
        </>
      );
    } else if (number >= 1) {
      return (
        <>
          {t(
            "You ordered the full available marking volume of this category. You are no longer able to order markings of this category."
          )}
        </>
      );
    } else {
      return (
        <>
          {t(
            "You ordered {{ percentage }} % of the available marking volume of this category. When reaching 100% you will no longer be able to order markings of this category.",
            { percentage: isNaN(percentage) ? 0 : percentage }
          )}
        </>
      );
    }
  };

  return (
    <>
      <InfoBar
        left={[<GroupHeader volume={data?.volumeControl} key={0} />]}
        withBottomMargin
      />
      <Paper elevation={0} className={classes.outline}>
        <div style={{ padding: "16px" }}>
          {data && data.volumeControl ? (
            <VolumeBar volume={data.volumeControl} />
          ) : (
            <>{t("No Volume to display")}</>
          )}
        </div>
        <Divider />
      </Paper>
      {data && data.volumeControl && (
        <div id="violation-message" style={{ padding: "16px" }}>
          <Typography
            style={{
              color: `${getColor(
                data.volumeControl.consumedQuota / data.volumeControl.quota
              )}`,
            }}
          >
            {getMessage(
              data.volumeControl.consumedQuota / data.volumeControl.quota
            )}
          </Typography>
        </div>
      )}
      <br />
      <LicensingEvent id={id}></LicensingEvent>
    </>
  );
};
