import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "markdown-to-jsx";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/HelpOutline";

// Apollo
import { useQuery } from "@apollo/client";
// Components
import { InfoBar } from "../../../Components/InfoBar";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& em": {
        fontWeight: "bold",
      },
    },
    helpIcon: { marginRight: theme.spacing(1) },
    wrapIcon: {
      verticalAlign: "middle",
      display: "inline-flex",
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      "& p": {
        display: "inline",
        margin: "0",
      },
    },
  })
);

const em = (props: { children: React.ReactNode }) => {
  return (
    <b>
      <em>{` ${props.children} `}</em>
    </b>
  );
};

const FaqQueryDocument = graphql(/* GraphQL */ `
  query FAQ {
    allContents(contentType: FAQ) {
      edges {
        node {
          body
        }
      }
    }
  }
`);

export const FAQPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const { data, error, loading } = useQuery(FaqQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const faqs = data?.allContents!.edges;

  return (
    <div className={classes.root}>
      <InfoBar
        left={[
          <Typography variant="h6" key={0}>
            {t("FREQUENTLY ASKED QUESTIONS")}
          </Typography>,
        ]}
        withBottomMargin
      />
      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        {faqs?.map((faq: any, index: number) => {
          const body = JSON.parse(faq.node.body);
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={`panel${index}a-header`}
              >
                <Typography variant="subtitle1" className={classes.wrapIcon}>
                  <HelpIcon className={classes.helpIcon} />
                  <>
                    <ReactMarkdown>{body.question}</ReactMarkdown>
                  </>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"div"} variant={"body1"}>
                  <ReactMarkdown>{body.answer}</ReactMarkdown>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};
