import React, { Suspense, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import MenuAppBar from "./Components/MenuAppBar";
import { Footer } from "./Components/Footer";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";

import { RegistryContext } from "./Providers/RegistryContext";
import { AuthContext } from "./Providers/Auth";

import { urls } from "./urls";

import { LoadingIndicator } from "./Components/LoadingIndicator";

// Popup News
import { NewsDialog } from "./NewsDialog";
import ErrorComponent from "./Components/ErrorComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.background.default,
    },
    flexWrapper: {
      // the flex wrapper is required to handle minHeight bug in IE 11
      // https://stackoverflow.com/questions/44700068/flexbox-min-height-and-ie11
      display: "flex",
      maxWidth: "1232px",
      margin: "0 auto",
    },
    rootDiv: {
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 0,
      paddingRight: 0,

      [theme.breakpoints.up("xs")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },

      [theme.breakpoints.down("xs")]: {
        minHeight: window.innerHeight,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    preventShrink: {
      // prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size.
      flexShrink: 0,
    },
    expandAndPreventShrink: {
      // prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size.
      flex: "1 0 auto",
    },
  })
);

const App = () => {
  const authContext = useContext(AuthContext);
  const { modules, getHomeModule } = useContext(
    RegistryContext
  ) as useRegistryResponse;

  const { isAuthenticated, permissions } = authContext;

  const theme = useTheme();
  const classes = useStyles(theme);

  const home = getHomeModule(isAuthenticated, permissions);

  useEffect(() => {
    /** disabled the language-selection, as the backend for the languages is currently not working
    const endpoint: any = {
      endpoint: "/locales/",
      method: "GET"
    };
     apiClient.call(endpoint).then((response: { status: number; data: any }) => {
      if ([HttpStatus.OK].includes(response.status)) {
        const options = i18next.options as InitOptionsExtended;
        response.data.forEach((locale: [string, string]) => {
          if (!options.supportedLanguages.includes(locale[0])) {
            options.supportedLanguages.push(locale[0]);
          }
        });
        options.supportedLanguages.sort();
        if (options.supportedLanguages.includes(i18next.language)) {
          i18next.changeLanguage("en");
        }
      }
    });
    */
  }, []);

  // wait until all modules have registered themselves
  if (modules.length === 0) {
    return null;
  }

  return (
    <>
      <div className={classes.background}>
        <BrowserRouter>
          <div className={classes.flexWrapper}>
            <div className={classes.rootDiv}>
              <div className={classes.preventShrink}>
                <MenuAppBar />
              </div>
              <div className={classes.expandAndPreventShrink}>
                <Suspense fallback={<LoadingIndicator />}>
                  <Switch>
                    {modules &&
                      modules.map((route: any) => {
                        const routeWithParams =
                          route.path +
                          (route.routeParams ? `/${route.routeParams}` : "");
                        return route.loginRequired ? (
                          <AuthenticatedRoute
                            exact
                            path={routeWithParams}
                            Component={route.component}
                            componentProps={route.props}
                            key={`route_${route.path}`}
                            requiredPermissions={route.permissions}
                            redirectToAuthenticate={urls["login"]}
                            redirectFallback={"/"}
                            excludeFromRedirect={[urls["logout"]]}
                            userPermissions={
                              permissions !== undefined ? permissions : []
                            }
                            isAuthenticated={
                              isAuthenticated !== undefined
                                ? isAuthenticated
                                : false
                            }
                          />
                        ) : (
                          <Route
                            key={`route_${route.path}`}
                            exact
                            path={routeWithParams}
                            render={(routeProps) =>
                              React.createElement(
                                route.component,
                                { ...route.props, ...routeProps },
                                null
                              )
                            }
                          />
                        );
                      })}
                    <Route
                      path="/error"
                      render={() => <ErrorComponent />}
                    />
                    <Route
                      path="/*"
                      render={() => <Redirect to={home.path} />}
                    />
                  </Switch>
                </Suspense>
              </div>
              {isAuthenticated && <NewsDialog />}
              <div className={classes.preventShrink}>
                <Footer />
              </div>
            </div>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
