import React from "react";
import { useTranslation } from "react-i18next";

// Components
import { StyledTabs, StyledTab, TabPanel } from "../components/TabsHandler";

import { AddressPanel } from "./AddressPanel";
import { ContactPanel } from "./ContactPanel";
import { UserPanel } from "./UserPanel";

// Flags
import { useFlag } from "../../../orderingFlags";

import { companyFragment } from "../CompanyProfile";
import { FragmentType } from "../../../gql";

interface Props {
  company: FragmentType<typeof companyFragment>;
}

export default ({ company }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  const showUserPanel = useFlag(["companyProfile", "userPanel"]);

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="tabs company profile"
      >
        <StyledTab label={t("Addresses")} />
        <StyledTab label={t("Contacts")} />
        {showUserPanel && <StyledTab label={t("Users")} />}
      </StyledTabs>

      <TabPanel value={value} index={0}>
        <AddressPanel company={company} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContactPanel company={company} />
      </TabPanel>
      {showUserPanel && (
        <TabPanel value={value} index={2}>
          <UserPanel />
        </TabPanel>
      )}
    </>
  );
};
