/// <reference path="../../../Types/index.d.ts" />
import React, { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";

import { AddressItem } from "../../../Components/AddressItem";
import { PlaceOrderMutationInput as OrderInput } from "../../../gql/graphql";
import { AddressFragment } from "../../../gql/graphql";

interface AddressFormControlProps {
  addresses: AddressFragment[];
  onChange: (orderUpdate: Partial<OrderInput>) => void;
  name: Extract<keyof OrderInput, "deliveryAddress" | "billingAddress">; // keyof OrderInput; //Extract<"a" | "b" | "c", "a" | "f">;
  title: string;
  value: string | undefined;
  hideDefault: boolean;
}

export const AddressFormControl = (props: AddressFormControlProps) => {
  const { t } = useTranslation();
  const { addresses, name, title, onChange, value } = props;

  const [defaultValue, setDefaultValue] = React.useState("");

  const categoryNames = {
    deliveryAddress: "DELIVERY",
    billingAddress: "BILLING",
  };

  useEffect(() => {
    const defaultValues: string[] = addresses
      .filter((address) =>
        address.metas.find(
          (meta) => meta.category === categoryNames[name] && meta.primary
        )
      )
      .map((address) => address.uuid);

    if (defaultValues.length > 0) {
      setDefaultValue(defaultValues[0]);
      onChange({ [name]: defaultValues[0] });
    } else {
      // if no primary address is founded
      onChange({ [name]: addresses[0].uuid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateValue = (_: ChangeEvent<{}>, value: string) => {
    console.log("updating value", name, value);
    onChange({ [name]: value });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {value && (
        <FormControl component="fieldset">
          <RadioGroup name={name} onChange={updateValue} value={value}>
            {addresses?.map((address, id: number) => {
              return address && !address.deleted ? (
                <FormControlLabel
                  style={{ margin: "8px" }}
                  key={id}
                  value={address.uuid.toString()}
                  control={<Radio />}
                  label={
                    <div>
                      {address.uuid === defaultValue && !props.hideDefault && (
                        <Typography
                          color="textSecondary"
                          variant="caption"
                        >{`(${t("default")})`}</Typography>
                      )}
                      <AddressItem
                        variant="body1"
                        dense={true}
                        address={address}
                      />
                    </div>
                  }
                />
              ) : null;
            })}
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
};
