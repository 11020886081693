/// <reference path="../../../../Types/index.d.ts" />
import React, { useEffect } from "react";

//Query
import {
  PlaceOrderMutationInput as DraftOrderInput,
  MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType,
} from "../../../../gql/graphql";
import { useQuery } from "@apollo/client";
import {
  myCompanyQuery,
  addressFragment,
  companyFragment,
} from "../../../CompanyProfile/CompanyProfile";
// Material
import { List, ListItem, Typography, Checkbox } from "@mui/material";

import { Step } from "./types";
import { useTranslation } from "react-i18next";

import { DisplayShippingDate } from "../ShippingDate";
import { DisplayOrderNumber } from "../PurchaseOrderNumber";
import { DisplayTransportationMethod } from "../TransportationMethod";
import {
  DisplayBillingAddress,
  DisplayDeliveryAddress,
} from "../AddressDisplay";
import { getFragmentData } from "../../../../gql";
import { LoadingIndicator } from "../../../../Components/LoadingIndicator";
import { useFlag } from "../../../../orderingFlags";

// this types relies on the fact that the order object has been validated properly !
type CompleteOrder = {
  [P in keyof Required<DraftOrderInput>]: NonNullable<DraftOrderInput[P]>;
};

export const StepComponent: Step["handler"] = (props) => {
  const { t } = useTranslation();
  const { types, termsChecked, setTermsChecked, updateOrder } = props;
  const { data, loading, error } = useQuery(myCompanyQuery);
  const hideAddresses = useFlag(["hideAddresses"]);

  const uniqueTypes = Array.from(new Set(types));

  useEffect(() => {
    if (data && hideAddresses) {
      const myCompanyData = getFragmentData(companyFragment, data?.myCompany);
      if (!myCompanyData?.billingAddresses)
        throw new Error(t("company information is incomplete"));

      const addresses = myCompanyData?.addresses?.edges?.map((item) => {
        const address = getFragmentData(addressFragment, item?.node);
        if (!address) throw Error;
        return address;
      });

      updateOrder((order) => ({
        ...order,
        deliveryAddress: addresses[0].uuid,
        billingAddress: addresses[0].uuid,
      }));
    } else if (
      uniqueTypes.includes(ProductType.Digital) &&
      uniqueTypes.length === 1
    ) {
      //In case of only Digital Products billing and deliver address has to be same
      updateOrder((order) => ({
        ...order,
        deliveryAddress: order.billingAddress,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hideAddresses]);

  if (loading) return <LoadingIndicator />;

  if (error) throw error;

  return (
    <>
      <List>
        {types.includes(ProductType.Physical) ? (
          <>
            <DisplayBillingAddress order={props.order as CompleteOrder} />
            <DisplayDeliveryAddress order={props.order as CompleteOrder} />
            <DisplayTransportationMethod order={props.order} />
            <DisplayShippingDate order={props.order} />
            <DisplayOrderNumber order={props.order} />
          </>
        ) : hideAddresses ? null : (
          <>
            <DisplayBillingAddress order={props.order as CompleteOrder} />
            <DisplayOrderNumber order={props.order} />
          </>
        )}

        <ListItem style={{ minHeight: "96px", padding: "0px 16px" }}>
          <Checkbox
            disableRipple
            checked={termsChecked}
            onChange={() => setTermsChecked(!termsChecked)}
          />
          <Typography variant="body2">
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "I agree to the <a href ='https://www.scribos.com/terms-of-delivery' target='_blank'>SCRIBOS: Terms of Delivery</a>"
                ),
              }}
            />
          </Typography>
        </ListItem>
      </List>
    </>
  );
};

export const useStepDefinition = (
  types: string[]
  // termsChecked: boolean,
  // setTermsChecked: any
): Step => {
  const { t } = useTranslation();

  return {
    title: t("Review Order"),
    id: "reviewOrder",
    handler: StepComponent,
    isValid: (_, activeStep) => {
      return activeStep >= (types.includes(ProductType.Physical) ? 2 : 1);
    },
  };
};

export const StepDefinition = { useStepDefinition };
