import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Material UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
// Utils
import { formatNumber } from "../../../Utils/commons";
import { AuthContext } from "../../../Providers/Auth";
import { FragmentType, getFragmentData } from "../../../gql";
import { EventFragment } from "./LicensingEvent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: { color: theme.palette.primary.main, textDecoration: "none" },
  })
);

const getAvatar = (string: string) => {
  const tokens = string.toUpperCase().split("_");

  return tokens.length > 1
    ? tokens[0].charAt(0).concat(tokens[1].charAt(0))
    : tokens[0].charAt(0);
};

export const EventItem = (props: {
  event: FragmentType<typeof EventFragment>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const event = getFragmentData(EventFragment, props.event);
  const authContext = useContext(AuthContext);
  const { permissions } = authContext;

  const title =
    ["WITHDRAWAL", "CANCEL_WITHDRAWAL"].includes(event.eventType) &&
    event.order ? (
      event.eventType === "WITHDRAWAL" ? (
        <>
          {t("Order")}
          <Link to={`/orders/${event.order.uuid}`} className={classes.link}>
            {` #${event.order.orderId}`}
          </Link>
          &nbsp;{t("created")}
        </>
      ) : (
        <>
          {t("Order")}
          <Link to={`/orders/${event.order.uuid}`} className={classes.link}>
            {` #${event.order.orderId}`}
          </Link>
          &nbsp;{t("canceled")}
        </>
      )
    ) : (
      `${event.eventType}`
    );

  /*
      CONTRACT: markings were added for
      VOID: markings were added for
      CANCEL_WITHDRAWAL: markings were added for
      WITHDRAWAL: markings were consumed by
  */
  let description;
  let amount = formatNumber(event.amount);
  let companyName = event.companyName;
  if (permissions!.includes("can_view_all")) {
    switch (event.eventType) {
      case "CONTRACT":
        description = t("{{amount}} markings were added for {{companyName}}", {
          amount,
          companyName,
        });
        break;

      case "VOID":
        description = t("{{amount}} markings were added for {{companyName}}", {
          amount,
          companyName,
        });
        break;

      case "CANCEL_WITHDRAWAL":
        description = t("{{amount}} markings were added for {{companyName}}", {
          amount,
          companyName,
        });
        break;

      case "WITHDRAWAL":
        description = t(
          "{{amount}} markings were consumed by {{companyName}}",
          {
            amount,
            companyName,
          }
        );
        break;

      default:
        break;
    }
  } else {
    // for normal user, do not display companyName
    description = ["CONTRACT", "VOID", "CANCEL_WITHDRAWAL"].includes(
      event.eventType
    )
      ? t("{{amount}} markings were added", {
          amount,
        })
      : t("{{amount}} markings were consumed", {
          amount,
        });
  }

  const ts = new Date(event.createdAt);
  const timestamp = ts.toUTCString();

  const avatar = getAvatar(event.eventType);

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              style={{
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                boxSizing: "border-box",
              }}
            >
              <Typography component="span" variant="body2" color="textPrimary">
                {description}
              </Typography>

              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {timestamp}
              </Typography>
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};
