/// <reference path="../../../../Types/index.d.ts" />
import { useTranslation } from "react-i18next";
import React from "react";
import { Step } from "./types";
import { LoadingIndicator } from "../../../../Components/LoadingIndicator";

//Query
import { useQuery } from "@apollo/client";

import {
  OrderOrderTransportationMethodChoices as Method,
  PlaceOrderMutationInput as OrderInput,
} from "../../../../gql/graphql";

import { AddressFormControl } from "../AddressFormControl";
import { TransportationMethod } from "../TransportationMethod";
import { ShippingDate } from "../ShippingDate";
import {
  myCompanyQuery,
  addressFragment,
  companyFragment,
} from "../../../CompanyProfile/CompanyProfile";
import { getFragmentData } from "../../../../gql";

export const StepComponent: Step["handler"] = (props) => {
  const { t } = useTranslation();

  const { data: companyData, loading, error } = useQuery(myCompanyQuery);

  if (loading) return <LoadingIndicator />;
  if (error) throw error;
  // Reading fragment data
  const myCompanyData = getFragmentData(
    companyFragment,
    companyData?.myCompany
  );

  if (
    !companyData ||
    !companyData.myCompany ||
    !myCompanyData?.deliveryAddresses ||
    !myCompanyData?.transportationMethods
  )
    throw new Error(t("company information is incomplete"));

  const deliveryAddresses = myCompanyData.deliveryAddresses.edges.map(
    (item) => {
      const address = getFragmentData(addressFragment, item?.node);
      if (!address) throw Error;
      return address;
    }
  );

  const transportationMethods = myCompanyData.transportationMethods;

  const onAddressChange = (orderUpdate: Partial<OrderInput>) => {
    props.updateOrder((order: any) => ({ ...order, ...orderUpdate }));
  };

  const onChange = (order: Partial<OrderInput>) => {
    props.updateOrder((oldOrder: any) => ({ ...oldOrder, ...order }));
  };

  return (
    <div>
      <TransportationMethod
        onChange={onChange}
        methods={
          transportationMethods.length > 0
            ? transportationMethods
            : [Method.ScribosHandled]
        }
        transportationMethod={props.order.transportationMethod}
      />

      <AddressFormControl
        name={"deliveryAddress"}
        title={t("Delivery Address")}
        hideDefault={false}
        addresses={deliveryAddresses}
        onChange={onAddressChange}
        value={
          props.order.deliveryAddress ? props.order.deliveryAddress : undefined
        }
      />

      <ShippingDate
        onChange={onChange}
        requestedShippingDate={props.order.requestedShippingDate}
      />
    </div>
  );
};

export const useStepDefinition = (): Step => {
  const { t } = useTranslation();

  return {
    title: t("Shipping Information"),
    id: "shippingInformation",
    handler: StepComponent,
    isValid: (order, activeStep) => {
      return (
        order &&
        order.transportationMethod !== undefined &&
        activeStep >= 1 &&
        order.deliveryAddress !== undefined
      );
    },
  };
};

export const StepDefinition = { useStepDefinition };
