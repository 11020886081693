import React, { Fragment } from "react";
import PieChartIcon from "@mui/icons-material/PieChart";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { VolumeControl } from "./VolumeControl";

type Props = {
  sortOrder: number;
};

export const VolumeControlModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"VolumeControl"}
        displayName={() => t("Volume Control")}
        path={"/volume_control"}
        routeParams={":id?"}
        icon={() => <PieChartIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <VolumeControl />}
        permissions={["can_order"]}
      />
    </Fragment>
  );
};
