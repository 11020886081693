import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Stack,
  CircularProgress,
  Divider,
  List,
  ListSubheader,
  Button,
} from "@mui/material";

//Query
import { useQuery, useLazyQuery } from "@apollo/client";

// Components
import { EventItem } from "./EventItem";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { graphql } from "../../../gql";

const LicensingEventQueryDocument = graphql(/* GraphQL */ `
  query LicensingEvent($markable: UUID, $perPage: Int, $pageNo: Int!) {
    licensingEvents(markable: $markable, perPage: $perPage, pageNo: $pageNo) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      licensingEvents {
        ...EventItem
      }
    }
  }
`);

export const EventFragment = graphql(/* GraphQL */ `
  fragment EventItem on LicensingEventNode {
    eventType
    amount
    order {
      uuid
      orderId
    }
    orderPosition {
      nrOfOrderedLabels
      quantity
    }
    markable {
      uuid
      markableType
      name
      description
    }
    createdAt
    companyName
  }
`);

export const LicensingEvent: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const ROWS_PER_PAGE = 20;

  const [getEvents, { data: eventData, loading: eventLoading }] = useLazyQuery(
    LicensingEventQueryDocument
  );

  const { data, error } = useQuery(LicensingEventQueryDocument, {
    variables: { markable: id, perPage: ROWS_PER_PAGE, pageNo: pageNo },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoad(false);
    setLoading(true);
    if (data && data.licensingEvents) {
      setLoading(false);
    }
  }, [data]);

  if (error) throw error;

  if (loading || eventLoading) {
    return <LoadingIndicator />;
  }

  const handleLoadButton = () => {
    setLoad(true);
    setPageNo(pageNo + 1);
    getEvents({
      variables: {
        markable: id,
        perPage: ROWS_PER_PAGE,
        pageNo: pageNo + 1,
      },
    });
  };

  const pageInfo = eventData
    ? eventData?.licensingEvents?.pageInfo
    : data?.licensingEvents?.pageInfo;

  const events = eventData
    ? eventData?.licensingEvents?.licensingEvents
    : data?.licensingEvents?.licensingEvents;

  return (
    <List subheader={<ListSubheader>{t("CURRENT EVENTS")}</ListSubheader>}>
      {!eventLoading && events && events.length > 0 ? (
        <>
          {events.map((event, index: number) => {
            return (
              <div key={index}>
                {event !== null && <EventItem event={event} />}
                {index < events.length - 1 && <Divider />}
              </div>
            );
          })}

          {pageInfo?.hasNextPage && (
            <Stack
              p={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                disabled={load}
                onClick={handleLoadButton}
              >
                {t("Load more events")}
                {load && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          )}
        </>
      ) : (
        <>{t("No Events to display")}</>
      )}
    </List>
  );
};
