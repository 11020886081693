import React from "react";
import { useTranslation } from "react-i18next";

import PageContainer from "../../Components/PageContainer";
import { Route, Switch } from "react-router-dom";

import { DetailPage } from "./pages/detailPage";
import { MainPage } from "./pages/mainPage";

export const VolumeControl = () => {
  const { t } = useTranslation();
  const header = [
    { name: t("Volume Control"), urlRegex: /^\/volume_control/ },
    {
      name: t("Details"),
      urlRegex: /^\/volume_control\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
    }
  ];

  return (
    <PageContainer
      title={t("Volume Control")}
      subTitle={""}
      header={{
        breadcrumbs: header
      }}
    >
      <Switch>
        <Route exact path={"/volume_control"} component={MainPage} />
        <Route exact path={"/volume_control/:id"} component={DetailPage} />
      </Switch>
    </PageContainer>
  );
};
